<template>
	<div>
		<div
			class="home-grid"
			:class="`device_is_${deviceType} index`">
			<headLeft class="col-span-2 h" />
			<headRight v-bind="oneGame" />
			<a	
				:data-gamename="item.gameName"
				:class="[0, 7, 12, 22].includes(index) ? 'big' : ''"
				v-for="(item, index) in curGames"
				:key="item.gameName + index"
				class="item as gameIcon gameDom"
				:href="'/click-games/' + item.gameName.toLowerCase().replaceAll(' ', '-')">
				<img
					loading="lazy"
					decoding="async"
					class="icon"
					:src="item.logoUrl"
					alt="" />
				<div
					class="gameName"
					v-show="deviceType !== 'mobile'">
					{{ item.gameName }}
				</div>
				<div class="co"></div>
			</a>
		</div>
		<!-- 移动端时，点击加载触发load事件 -->
		<!-- pc，切换页面pageChange -->
		<load-btn
			@load="load"
			:ifAllLoad="ifAllLoad"
			@pageChange="onPageChange"
			:currentPage="currentPage"
			:pageSize="112"
			:total="total" />

		<bottomCate />
		<div class="intro">
			<h1>
				Why Choose
				<a
					href="/"
					style="color: rgb(40 171 250)">
					Yoloplay.games
				</a>
				?
			</h1>

			<h2>1. Extensive Game Library:</h2>
			<p>Our site features a diverse array of games across multiple genres, including action, puzzle, strategy, adventure, sports, and more. We continuously update our library with the latest titles to ensure there's always something new and exciting for you to play.</p>

			<h2>2. HTML5 Technology:</h2>
			<p>All our games are built using HTML5 technology, which means they are compatible with all modern web browsers and devices. Whether you're on a desktop, laptop, tablet, or smartphone, you can enjoy our games without any hassle.</p>

			<h2>3. User-Friendly Interface:</h2>
			<p>
				Navigating
				<a href="/">Yoloplay.games</a>
				is a breeze, thanks to our clean and intuitive interface. Easily browse through our game categories or use the search function to find your favorite games quickly.
			</p>

			<h2>4. No Registration Required:</h2>
			<p>Start playing immediately without the need to sign up. However, creating an account allows you to save your progress, track your achievements, and participate in our vibrant gaming community.</p>

			<h2>5. High-Quality Games:</h2>
			<p>
				We are committed to offering only the best games. Each game on
				<a href="/">Yoloplay.games</a>
				is carefully selected to ensure it meets our high standards for graphics, gameplay, and overall user experience.
			</p>

			<h2>6. Community and Social Features:</h2>
			<p>Join our community of gamers to share tips, strategies, and experiences. Compete in leaderboards and see how you rank against other players.</p>

			<h2>Popular Game Categories</h2>
			<ul>
				<li>
					<a href="/action-games">Action Games</a>
					: Experience the thrill of fast-paced action with our selection of shooters, fighting games, and more.
				</li>
				<li>
					<a href="/puzzle-games">Puzzle Games</a>
					: Challenge your mind with a variety of puzzles, including match-3 games, brainteasers, and more.
				</li>
				<li>
					<a href="/strategy-games">Strategy Games</a>
					: Test your strategic thinking with games that require planning, resource management, and tactical skills.
				</li>
				<li>
					<a href="/adventure-games">Adventure Games</a>
					: Embark on epic quests and explore fantastical worlds with our adventure games.
				</li>
				<li>
					<a href="/sports-games">Sports Games</a>
					: Enjoy realistic sports simulations, from soccer and basketball to racing and golf.
				</li>
				<li>
					<a href="/casual-games">Casual Games</a>
					: Perfect for a quick gaming session, our casual games are easy to pick up and play.
				</li>
				<li>
					<a href="/racing-games">Racing Games</a>
					: Speed through tracks and race to victory.
				</li>
				<li>
					<a href="/match-3-games">Match 3 Games</a>
					: Align tiles and achieve high scores.
				</li>
				<li>
					<a href="/shooting-games">Shooting Games</a>
					: Test your aim in action-packed scenarios.
				</li>
				<li>
					<a href="/number-games">Number Games</a>
					: Sharpen your math skills with fun challenges.
				</li>
			</ul>

			<h2>How to Get Started</h2>
			<ol>
				<li>
					<strong>Step 1: Browse the Library:</strong>
					Explore our extensive game library by browsing through different categories or using the search function.
				</li>
				<li>
					<strong>Step 2: Select a Game:</strong>
					Click on a game that interests you to open the game page, where you can read a brief description and view screenshots.
				</li>
				<li>
					<strong>Step 3: Start Playing:</strong>
					Click the "Play Now" button to launch the game directly in your browser.
				</li>
			</ol>
		</div>
	</div>
</template>

<script>
import LoadBtn from '../components/loadBtn.vue'
import { mapState } from 'vuex'
import { getList } from '../api'
export default {
	components: { LoadBtn },
	data() {
		return {
			ob: null,
			allGames: [],
			curGames: [],
			currentPage: 1,
			total: 100,
			ifAllLoad: false,
			// 传递给head的
			oneGame: {
				gameName: '',
				logoUrl: '',
			},
		}
	},
	computed: {
		...mapState(['deviceType']),
	},
	watch: {
		allGames() {
			this.oneGame.gameName = this.curGames[23]?.gameName
			this.oneGame.logoUrl = this.curGames[23]?.logoUrl
		},
	},
	async mounted() {
		if (this.$store.state.deviceType === 'mobile') {
			await this.getList({
				pageSize: 24,
				pageNum: this.currentPage,
			})
			this.addCurGame()
		} else {
			await this.getList({
				pageSize: 112,
				pageNum: this.currentPage,
			})
		}

		setTimeout(() => {
			this.startOb()	
		});
	},
	methods: {
		startOb () {
			const o = new IntersectionObserver(
				(entries, observer) => {
					entries.forEach((item) => {
						// isIntersecting 表示是否出现
						if (item.isIntersecting) {
							this.$logEvent('game_icon_expose:' + item.target.dataset.gamename, 'expose')
							observer.unobserve(item.target)
						}
					})
				},
				{
					root: null,
					rootMargin: '0px',
					threshold: 0.1,
				},
			)
			this.ob = o
			let games =	document.querySelectorAll('.gameDom')
			for (const el of games) {
				o.observe(el)
			}	

		},
		// 每次请求一分页新数据，再把10个放到当前数组
		async load() {
			// 全部加载完就不请求
			await this.getList({
				pageSize: 24,
				pageNum: ++this.currentPage,
			})
			this.addCurGame()
			setTimeout(() => {
				this.startOb()	
			});
		},
		// 移动端点击加载时，把获取到的allGame游戏添加进当前curGame数组
		addCurGame() {
			// 初次加载24个
			if (this.curGames.length === 0) {
				this.curGames.push(...this.allGames.splice(0, 24))
			} else {
				// 每次加载添加10个进当前
				this.curGames.push(...this.allGames.splice(0, 10))
				if (this.allGames.length === 0) {
					this.ifAllLoad = true
					return
				}
			}
		},
		// mobile:获取新分页数据添加进allGames;pc新分页替换curGames
		async getList({ type = '', pageSize = 24, pageNum = 1 }) {
			this.$store.state.collocationLoading = true
			// console.log(type, 'pageSize', pageSize, 'pageNum', pageNum)
			let arr = []
			const { infoList, total } = await getList(arguments[0])
			arr = infoList
			this.total = total
			if (this.deviceType === 'mobile') {
				this.allGames.push(...arr)
			} else {
				this.curGames.splice(0, this.curGames.length, ...arr)
				// console.log(this.curGames)
			}
			this.$store.state.collocationLoading = false
			this.$logEvent('page_home', 'expose')
		},
		async onPageChange(curIndex) {
			this.currentPage = curIndex
			await this.getList({ pageNum: this.currentPage, pageSize: 112 })
			setTimeout(() => {
				window.scrollTo(0, 0)
			})
			this.startOb()
		},
	},
}
</script>
<style lang="scss" scoped>
.home-grid {
	.item {
		overflow: hidden;
		position: relative;
		border-radius: rem(20);
		box-shadow: rem(0) rem(5) rem(15) rem(0) rgba(0, 0, 0, 0.4);
		// background-color: #fff;
		&.big {
			grid-column: span 2 / span 2;
			grid-row: span 2 / span 2;
		}
	}
	.icon {
		height: 100%;
		width: 100%;
		vertical-align: middle;
		position: absolute;
		inset: 0px;
		object-fit: cover;
	}
}
.intro {
	ul li::before {
		color: rgb(85, 169, 243) !important;
	}
	background-color: #fff;
	padding: rem(30);
	margin-top: rem(50);
	border-radius: rem(20);

	h1 {
		color: #002b51 !important;
		font-size: rem(40);
		font-style: normal;
		font-weight: 900;
		line-height: rem(60); /* 150% */
		letter-spacing: rem(1);
	}
	h2 {
		color: #002b51 !important;
		font-size: rem(30);
		font-style: normal;
		font-weight: 900;
		line-height: rem(60); /* 150% */
		letter-spacing: rem(1);
	}
	p {
		color: #002b51 !important;
		font-family: Roboto;
		font-size: rem(24);
		font-style: normal;
		font-weight: 400;
		line-height: rem(40); /* 166.667% */
		letter-spacing: rem(1);
	}
	li {
		color: #002b51 !important;
		font-family: Roboto;
		font-size: rem(24);
		font-style: normal;
		font-weight: 400;
		line-height: rem(40); /* 166.667% */
		letter-spacing: rem(1);
	}
	a {
		text-decoration: none;
		color: rgb(85, 169, 243);
		position: relative;
	}
	a:hover {
		color: #31699e;
		text-decoration: underline;
	}
}
.gameIcon:hover {
	transform: translateY(-4px);
	.gameName {
		display: block;
	}
}
.gameIcon .co {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.5));
	opacity: 0;
}
.gameIcon:hover .co {
	animation: coAni 0.2s ease-out forwards;
}
.gameIcon:hover .gameName {
	animation: nameAni 0.3s ease-out forwards;
}
@keyframes coAni {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes nameAni {
	from {
		opacity: 0;
		bottom: 0;
	}
	to {
		opacity: 1;
		bottom: 10px;
	}
}
.gameName {
	display: none;
	text-align: center;
	color: #fff;
	font-family: Roboto;
	font-size: 13px;
	position: absolute;
	z-index: 10;
	left: 50%;
	transform: translateX(-50%);
}
.big .gameName {
	font-size: 20px !important;
}
</style>
