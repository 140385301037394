import axios from "@/utils/request";
import { resolve } from "path";
export async function getList(req) {
   const res = await axios({
        method: 'get',
        url: '/yoloGame/selectGamePage',
        params: req
    })
    return res.data
}
export async function gameDetail(req) {
   const res = await axios({
        method: 'get',
        url: '/yoloGame/getGameInfoById',
        params: req
    })
    return res.data
}
